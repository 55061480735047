import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const DissolutionAccessories = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">GC Columns</div>
    <div className="app__para">In the pharmaceutical industry, drug dissolution testing is routinely used to provide critical in vitro drug release information for both quality control purposes, i.e., to assess batch-to-batch consistency of solid oral dosage forms such as tablets, and drug development, i.e., to predict in vivo drug release profiles.</div>
    <div className="app__products_product">
      {
        data.dissolution_accessories.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default DissolutionAccessories