import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const HplcAccessories = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">HPLC Accessories</div>
    <div className="app__para">High-performance liquid chromatography (HPLC), formerly referred to as high-pressure liquid chromatography, is a technique in analytical chemistry used to separate, identify, and quantify each component in a mixture.</div>
    <div className="app__products_product">
      {
        data.hplcaccessories.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default HplcAccessories