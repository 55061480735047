import React from 'react'
import './App.css'
import {Creator, Footer, Navbar} from './components'
import {Home, HplcColumn, Impurities, GcColumn, LabGlassware, DissolutionAccessories, Instruments, HplcAccessories, GcAccessories, SyringeFilters } from './pages'
import { Routes, Route } from 'react-router-dom'
import Vials from './pages/Vials'
const App = () => {
  return (
    <div>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/impurities' element={<Impurities/>} />
        <Route path='/hplccolumn' element={<HplcColumn/>} />
        <Route path='/gccolumn' element={<GcColumn/>} />
        <Route path='/lab_glassware' element={<LabGlassware/>} />
        <Route path='/dissolution_accessories' element={<DissolutionAccessories/>} />
        <Route path='/instruments' element={<Instruments/>} />
        <Route path='/hplc_accessories' element={<HplcAccessories/>} />
        <Route path='/gc_accessories' element={<GcAccessories/>} />
        <Route path='/syringe_filters' element={<SyringeFilters/>} />
        <Route path='/vials' element={<Vials/>} />

      </Routes>
      <Footer/>
      <Creator/>
    </div>
  )
}

export default App