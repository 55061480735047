import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const GcColumn = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">GC Columns</div>
    <div className="app__para">GC columns contain a stationary phase, typically a polymeric material. It is through the interaction between the analytes and this stationary phase within the column that separation of the analyte into their molecular components can occur. There are two types of GC columns: packed columns and capillary columns.</div>
    <div className="app__products_product">
      {
        data.gccolumn.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default GcColumn