import React from 'react'
import images from '../../constants'
import './about.css'

const About = () => {
  return (
    <div className="app__about section__padding " id='about'>
      <div className="app__heading" style={{ textAlign: 'center' , lineHeight:'0px' }}>About Us</div>
      <div className="app__about_content app__wrapper">
        <div className="app__wrapper_img">
          <img src={images.about} alt="" />
        </div>
        <div className="app__wrapper_info">
          <div className="app__paraHeading">Who We Are</div>
          {/* ClassName : animate-charcter */}
          <p className="app__para "><b>Synchem Chromatography technologies</b> is one of the leading delivering partner in chromatography technologies. We are expert in delivering the products like HPLC Columns, GC Columns, Lab Glassware, Dissolution Accessories, GC Accessories, Vials , Syringe Filters and more. We are committed to providing our customers with the best possible service and support.</p>
          <p className="app__para ">
            We help Pharma Companies and Wholesalers at each and every statge of business life cycle. We are guarentee this is the best place you can find high quality chromatography products with very low prices.
          </p>

          <div className="app__about_goals">
            <div className="app__about_goal">
              <div className="app__paraHeading">Our Mission</div>
              <img src={images.mission} alt="" />
              <div className="app__para">To deliver quality products with efficient services while continuously expanding our product offerings.</div>
            </div>
            <div className="app__about_goal ">
              <div className="app__paraHeading ">Our Vision</div>
              <img src={images.vision} alt="" />
              <div className="app__para animate-charcter">Always Look to be named as the Quality Distributor of High Performance Products in the Analytical Industry.</div>
            </div>
            <div className="app__about_goal">
              <div className="app__paraHeading">Our Values</div>
              <img src={images.values} alt="" />
              <div className="app__para">We are always keen and eager to serve the Analytical Industry and meet their needs at every Level.</div>
            </div>
          </div>
          <button className="button" style={{margin : '10px 4px'}}>Contact Us</button>
        </div>
      </div>
    </div>
  )
}

export default About