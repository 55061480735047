import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'
import SF0 from '../../assets/Syringe_Filters/sf001.jpg'

const SyringeFilters = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">Syringe Filters</div>
    <div className="app__para">Syringe filters may be used to remove particles from a sample, prior to analysis by HPLC or other techniques involving expensive instruments. Particles easily damage an HPLC due to the narrow bore and high pressures within.</div>
    <img src={SF0} alt="" style={{minWidth : '100%'}} />
    {/* <div className="app__products_product">
      {
        data.syringefilters.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div> */}
  </div>
  )
}

export default SyringeFilters