import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const Instruments = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">Instruments</div>
    <div className="app__para">Laboratory instrument or equipment is generally used to either perform an experiment or to take measurements and gather data. Larger or more sophisticated equipment is generally called a scientific instrument.</div>
    <div className="app__products_product">
      {
        data.instruments.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default Instruments