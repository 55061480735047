import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const GcAccessories = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">GC Accessories</div>
    <div className="app__para">Gas chromatography (GC) is a common type of chromatography used in analytical chemistry for separating and analyzing compounds that can be vaporized without decomposition. Typical uses of GC include testing the purity of a particular substance, or separating the different components of a mixture.[1] In preparative chromatography, GC can be used to prepare pure compounds from a mixture</div>
    <div className="app__products_product">
      {
        data.gcaccessories.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default GcAccessories