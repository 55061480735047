import bg from '../assets/bg.png';
import mission from '../assets/mission.png';
import vision from '../assets/vision.png';
import values from '../assets/values.png';
import about from '../assets/about.png';
import logo from '../assets/logo.png';
import glasswareOne from '../assets/glassware/1.jpeg';
import glasswareTwo from '../assets/glassware/2.jpeg';
import glasswareThree from '../assets/glassware/3.jpg';
import glasswareFour from '../assets/glassware/4.jpg';
import glasswareFive from '../assets/glassware/5.jpg';
import glasswareSix from '../assets/glassware/6.jpg';
import hplc_column from '../assets/clipart/hplc_column.svg';
import gc_column from '../assets/clipart/gc_column.svg';
import da from '../assets/clipart/da.svg';
import gc_a from '../assets/clipart/gc_a.svg';
import hplc_a from '../assets/clipart/hplc_a.svg';
import instruments from '../assets/clipart/instruments.svg';
import labglassware from '../assets/clipart/labglassware.svg';
import syringe_filters from '../assets/clipart/syringe_filters.svg';
import vials from '../assets/clipart/vials.svg';
import technical from '../assets/services/technical.png';
import fast from '../assets/services/fast.png';
import replacement from '../assets/services/replacement.png';
import always from '../assets/services/always.png';
import hm1 from '../assets/homescreen/hm1.jpg';
import hm2 from '../assets/homescreen/hm2.jpg';
import hm3 from '../assets/homescreen/hm3.jpg';
import hm4 from '../assets/homescreen/hm4.jpeg';
import hm5 from '../assets/homescreen/hm5.jpg';
import hm6 from '../assets/homescreen/hm6.jpg';
import Gallery1 from '../assets/Gallery/1.jpg';
import Gallery2 from '../assets/Gallery/2.jpg';
import Gallery3 from '../assets/Gallery/3.jpg';
import Gallery4 from '../assets/Gallery/4.jpg';
import Gallery5 from '../assets/Gallery/5.jpg';
import Gallery6 from '../assets/Gallery/6.jpg';
import Gallery7 from '../assets/Gallery/7.jpg';
import Gallery8 from '../assets/Gallery/8.jpg';
import imp1 from '../assets/impurities/imp1.jpg';
import impurities1 from '../assets/impurities/impurities1.png';
import impurities2 from '../assets/impurities/impurities2.png';
import impurities3 from '../assets/impurities/impurities3.jpg';
import impurities4 from '../assets/impurities/impurities4.png';
import impurities5 from '../assets/impurities/impurities5.jpg';
import impurities6 from '../assets/impurities/impurities6.png';
import impurities7 from '../assets/impurities/impurities7.jpg';
import impurities8 from '../assets/impurities/impurities8.png';
import impurities9 from '../assets/impurities/impurities9.png';
import impurities10 from '../assets/impurities/impurities10.png';
import impurities11 from '../assets/impurities/impurities11.png';
import impurities12 from '../assets/impurities/impurities12.png';
import impurities13 from '../assets/impurities/impurities13.png';
import impurities14 from '../assets/impurities/impurities14.png';
import impurities15 from '../assets/impurities/impurities15.jpg';
import impurities16 from '../assets/impurities/impurities16.png';
import impurities17 from '../assets/impurities/impurities17.jpg';
import impurities18 from '../assets/impurities/impurities18.jpg';
import impurities19 from '../assets/impurities/impurities19.jpg';
import impurities20 from '../assets/impurities/impurities20.png';
import impurities21 from '../assets/impurities/impurities21.png';
import impurities22 from '../assets/impurities/impurities22.png';
import impurities23 from '../assets/impurities/impurities23.png';
import impurities24 from '../assets/impurities/impurities24.png';
import impurities25 from '../assets/impurities/impurities25.png';
import impurities26 from '../assets/impurities/impurities26.png';
import hplc1 from '../assets/HPLC_Columns/HPLC1.jpeg';
import gccolumn1 from '../assets/GC_Columns/gccolumn1.jpg';
import gccolumn2 from '../assets/GC_Columns/gccolumn2.png';
import gccolumn3 from '../assets/GC_Columns/gccolumn3.jpg';
// import lg1 from '../assets/lab_glassware/lg1.jpg';
// import lg2 from '../assets/lab_glassware/lg2.jpg';
// import lg3 from '../assets/lab_glassware/lg3.jpg';
// import lg4 from '../assets/lab_glassware/lg4.jpg';
// import lg5 from '../assets/lab_glassware/lg5.jpg';
// import lg6 from '../assets/lab_glassware/lg6.jpg';
// import lg7 from '../assets/lab_glassware/lg7.jpg';
// import lg8 from '../assets/lab_glassware/lg8.jpg';
// import lg9 from '../assets/lab_glassware/lg9.jpg';
// import lg10 from '../assets/lab_glassware/lg10.jpg';
// import lg11 from '../assets/lab_glassware/lg11.jpg';
// import lg12 from '../assets/lab_glassware/lg12.jpg';
// import lg13 from '../assets/lab_glassware/lg13.jpg';
import lg1 from '../assets/lab_glassware/lg1.jpg';
import lg2 from '../assets/lab_glassware/lg2a.jpg';
import lg3 from '../assets/lab_glassware/lg3.jpg';
import lg4 from '../assets/lab_glassware/lg4.jpg';
import lg5 from '../assets/lab_glassware/lg5.jpg';
import lg6 from '../assets/lab_glassware/lg6.jpg';
import lg7 from '../assets/lab_glassware/lg7.jpg';
import lg8 from '../assets/lab_glassware/lg8.jpg';
import lg9 from '../assets/lab_glassware/lg9.jpg';
import lg10 from '../assets/lab_glassware/lg10.jpg';
import lg11 from '../assets/lab_glassware/lg11.jpg';
import lg12 from '../assets/lab_glassware/lg12.jpg';
import lg13 from '../assets/lab_glassware/lg13.jpg';
import lg14 from '../assets/lab_glassware/lg14.jpg';
import lg15 from '../assets/lab_glassware/lg15.jpg';
import lg16 from '../assets/lab_glassware/lg16.jpg';
import lg17 from '../assets/lab_glassware/lg17.jpg';
import lg18 from '../assets/lab_glassware/lg18.jpg';
import lg19 from '../assets/lab_glassware/lg19.jpg';
import lg20 from '../assets/lab_glassware/lg20.jpg';
import lg21 from '../assets/lab_glassware/lg21.jpg';
import lg22 from '../assets/lab_glassware/lg22.jpg';
import lg23 from '../assets/lab_glassware/lg23.jpg';
import lg24 from '../assets/lab_glassware/lg24.jpg';
import lg25 from '../assets/lab_glassware/lg25.jpg';
import lg26 from '../assets/lab_glassware/lg26.jpg';
import lg27 from '../assets/lab_glassware/lg27.jpg';
import lg28 from '../assets/lab_glassware/lg28.jpg';
import lg29 from '../assets/lab_glassware/lg29.jpg';
import lg30 from '../assets/lab_glassware/lg30a.jpg';
import lg31 from '../assets/lab_glassware/lg31.jpg';
import lg32 from '../assets/lab_glassware/lg32.jpg';
import lg33 from '../assets/lab_glassware/lg33.jpg';
import lg34 from '../assets/lab_glassware/lg34.jpg';
import lg35 from '../assets/lab_glassware/lg35.jpg';
import lg36 from '../assets/lab_glassware/lg36.jpg';
import lg37 from '../assets/lab_glassware/lg37.jpg';
import lg38 from '../assets/lab_glassware/lg38.jpg';
import lg39 from '../assets/lab_glassware/lg39.jpg';
import lg40 from '../assets/lab_glassware/lg40.jpg';
import lg41 from '../assets/lab_glassware/lg41.jpg';
import lg42 from '../assets/lab_glassware/lg42.jpg';
import lg43 from '../assets/lab_glassware/lg43.jpg';
import lg44 from '../assets/lab_glassware/lg44.jpg';
import lg45 from '../assets/lab_glassware/lg45.jpg';
import lg46 from '../assets/lab_glassware/lg46.jpg';
import lg47 from '../assets/lab_glassware/lg47.jpg';
import lg48 from '../assets/lab_glassware/lg48.jpg';
import lg49 from '../assets/lab_glassware/lg49.jpg';
import lg50 from '../assets/lab_glassware/lg50.jpg';
import lg51 from '../assets/lab_glassware/lg51.jpg';
import lg52 from '../assets/lab_glassware/lg52.jpg';
import lg53 from '../assets/lab_glassware/lg53.jpg';


import da1 from '../assets/dissolution_accessories/da1.jpeg';
import da2 from '../assets/dissolution_accessories/da2.jpeg';
import da3 from '../assets/dissolution_accessories/da3.jpeg';
import da4 from '../assets/dissolution_accessories/da4.jpeg';
import da5 from '../assets/dissolution_accessories/da5.jpeg';
import da6 from '../assets/dissolution_accessories/da6.jpeg';
import da7 from '../assets/dissolution_accessories/da7.jpeg';
import da8 from '../assets/dissolution_accessories/da8.jpeg';
import da9 from '../assets/dissolution_accessories/da9.jpeg';
import da10 from '../assets/dissolution_accessories/da10.jpeg';
import da11 from '../assets/dissolution_accessories/da11.png';
import da12 from '../assets/dissolution_accessories/da12.jpeg';
import da13 from '../assets/dissolution_accessories/da13.jpeg';
import clip1 from '../assets/clipart/imp1.png';
import clip2 from '../assets/clipart/hc1.png';
import clip3 from '../assets/clipart/gc1.png';
import clip4 from '../assets/clipart/lb1.png';
import clip5 from '../assets/clipart/da1.png';
import clip6 from '../assets/clipart/ins1.png';
import clip7 from '../assets/clipart/ha1.png';
import clip8 from '../assets/clipart/gca1.png';
import clip9 from '../assets/clipart/sf1.png';
import clip10 from '../assets/clipart/vs1.png';
import ins1 from '../assets/instruments/ins1.jpg';
import ins2 from '../assets/instruments/ins2.jpg';
import ins3 from '../assets/instruments/ins3.jpg';
import ins4 from '../assets/instruments/ins4.jpg';
import ins5 from '../assets/instruments/ins5.jpg';
import ins6 from '../assets/instruments/ins6.jpg';
import ins7 from '../assets/instruments/ins7.jpg';
import ins8 from '../assets/instruments/ins8.jpg';
import hplc_a1 from '../assets/HPLC_Accessories/hplc_a1.jpeg';
import hplc_a2 from '../assets/HPLC_Accessories/hplc_a2.jpg';
import hplc_a3 from '../assets/HPLC_Accessories/hplc_a3.jpg';
import GC_A1 from '../assets/GC_Accessories/GC_A1.jpg';
import GC_A2 from '../assets/GC_Accessories/GC_A2.jpg';
import SF1 from '../assets/Syringe_Filters/sf1.jpg';
import SF2 from '../assets/Syringe_Filters/sf2.jpeg';
import SF0 from '../assets/Syringe_Filters/sf001.jpg'
import vials1 from '../assets/vials/vials1.jpg';
import vials2 from '../assets/vials/vials2.jpg';


export default {
    bg, logo, glasswareOne, glasswareTwo, glasswareThree, glasswareFour, glasswareFive, glasswareSix, about, hplc_column, gc_column, da, gc_a, hplc_a, instruments, labglassware, syringe_filters, vials, mission, vision, values, technical, fast, always, replacement,hm1, hm2, hm3, hm4, hm5, hm6, Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8,imp1, impurities1, impurities2, impurities3, impurities4, impurities5, impurities6, impurities7, impurities8, impurities9, impurities10, impurities11, impurities12, impurities13, impurities14, impurities15, impurities16, impurities17, impurities18, impurities19, impurities20, impurities21, impurities22, impurities23, impurities24, impurities25, impurities26, hplc1, gccolumn1, gccolumn2, gccolumn3, lg1, lg2, lg3, lg4, lg5, lg6, lg7, lg8, lg9, lg10, lg11,lg12, lg13, lg14, lg15, lg16, lg17, lg18, lg19, lg20, lg21, lg22, lg23, lg24, lg25, lg26, lg27, lg28, lg29, lg30, lg31, lg32, lg33, lg34, lg35, lg36, lg37, lg38, lg39, lg40, lg41, lg42, lg43, lg44, lg45, lg46, lg47, lg48, lg49, lg50, lg51, lg52, lg53,  da1, da2, da3, da4, da5, da6, da7, da8, da9, da10, da11, da12, da13, clip1, clip2, clip3, clip4,clip5, clip6, clip7, clip8, clip9, clip10, ins1, ins2, ins3, ins4, ins5, ins6, ins7, ins8, hplc_a1, hplc_a2, hplc_a3, GC_A1, GC_A2, SF1, SF2, SF0, vials1, vials2
}