import React from 'react'
import './productcard.css'

const ProductCard = ({ product: { imgUrl, title } }) => (
    <div className="app__productcard">
      <img src={imgUrl} alt="awards" />
      <div className="app__paraHeading">{title}</div>
    </div>
  );

export default ProductCard