import React from 'react';
import images from '../../constants';
import {FaFacebookF, FaTwitter, FaInstagram} from 'react-icons/fa'
import './footer.css'

const Footer = () => {
  return (
    <div className="app__footer">
      <div className="app__footer-section-One">
        <div className="app__footer-section_img">
          <img src={images.logo} alt="" />
        </div>
        <div className="app__para animate-charcter">Synchem Chromatography Technologies Is One Of The Leading Delivering Partner In Chromatography Technologies. We Are Expert In Delivering The Products Like HPLC Columns, GC Columns, Lab Glassware, Dissolution Accessories, GC Accessories, Vials , Syringe Filters And More.</div>
        <div className="app__footer-section_social">
          <FaFacebookF size={27} style={{color:'var(--color-base)'}} />
          <FaInstagram size={27} style={{color:'var(--color-base)' , margin:'0px 15px'}}/>
          <FaTwitter size={27} style={{color:'var(--color-base)'}}/>
        </div>
      </div>
      <div className="app__footer-section">
        <div className="app__paraHeading">Menu</div>
        <div className="app__para">Home</div>
        <div className="app__para">Products</div>
        <div className="app__para">Services</div>
        <div className="app__para">Gallery</div>
        <div className="app__para">Contact</div>
      </div>
      <div className="app__footer-section">
        <div className="app__paraHeading">Products</div>
        <div className='app__para'>HPLC & GC Column</div>
        <div className='app__para'>Lab Glassware</div>
        <div className='app__para'>Dissolution Accessories</div>
        <div className='app__para'>Intsruments</div>
        <div className='app__para'>Lab Glassware</div>
        <div className='app__para'>Lab Glassware</div>
        <div className='app__para'>Lab Glassware</div>
      </div>
      <div className="app__footer-section">
        <div className="app__paraHeading">Contact Us</div>
        <div className='app__para'>HIG-613, Flat-No:302, <br /> 6th Phase,
        KPHB Colony,<br />Hyderabad,
        Telangana-500072</div>
        <div className='app__para'><b>Email</b> : Sales@synchemlab.com</div>
        <div className='app__para'><b>Ph No</b> : +91-8500613771</div>
      </div>
    </div>
  )
}

export default Footer