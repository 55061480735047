import React from 'react'
import { ImLocation , ImLocation2, ImPhone} from 'react-icons/im'
import {HiOutlineMail} from 'react-icons/hi'
import './contact.css'

const Contact = () => {
    return (
        <div className="app__contact section__padding" id='contact'>
            <div className="app__heading">Contact Us</div>
            <div className="app__para">We're here. Our door is always open for a good cup of coffe</div>
            <div className="app__contact_container">
                <div className="app__contact_container-left">
                    <div className="app__contact_container-section">
                        <ImLocation size={27} />
                        <div className="app__paraHeading">USA Branch</div>
                        <address className='app__para'>44 Mammoth Road, Manchestar, <br /> New Jersy-03109, <br /> United States</address>
                    </div>
                    <div className="app__contact_container-section">
                        <ImLocation2 size={27} />
                        <div className="app__paraHeading">Indian Branch</div>
                        <address className='app__para'>HIG-613, Flat-No:302, 6th Phase, <br /> KPHB Colony, Hyderabad, <br /> Telangana-500072</address>
                    </div>
                    <div className="app__contact_container-section">
                        <ImPhone size={27} />
                        <div className="app__paraHeading">Phone Number</div>
                        <address className='app__para'>+91-8500713661</address>
                    </div>
                    <div className="app__contact_container-section">
                        <HiOutlineMail size={27} />
                        <div className="app__paraHeading">Email</div>
                        <address className='app__para customize'>sales@<span>synchemlab.com </span></address>
                    </div>
                </div>
                <div className="app__contact_container-right">
                    <form className="form" action='https://formspree.io/f/mnqylrjn' method='POST'>
                        <h2 className="app__paraHeading">Get In Touch</h2>
                        <input type="text" name='username' id='username' placeholder='Enter Your Name'/>
                        <input type="email" name="email" id='email' placeholder='Enter Your Email' />
                        <textarea name="textarea" id="textarea" cols="30" rows="10" placeholder='Enter Your Message'></textarea>
                        <input type="submit" value="Submit" className='app__contact_container-right_submit' />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact