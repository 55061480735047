import React, { useState } from 'react'
import './navbar.css'
import { ImMenu3, ImMenu4 } from 'react-icons/im'
import images from '../../constants'
import { Route, Routes, Link, useNavigate } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate= useNavigate();

  const handleNavigate=()=>{
      navigate('/#about')
    window.scrollTo(0, 500);
  }

  return (
    <div className="app__navbar ">
      {/* Logo  */}
      <div className="app__navbar-logo">
        <Link to="/"><img src={images.logo} alt="" /></Link>
      </div>
      <div className="app__navbar-links">
        <ul className="app__navbar-links_top">
          <li className="app__paraHeading"><Link to='/'>Home</Link></li>
          <li className="app__paraHeading"><span smooth={true} onClick={handleNavigate} >Products</span></li>
          <li className="app__paraHeading"><HashLink to="#about" smooth={true}>About</HashLink></li>
          <li className="app__paraHeading"><HashLink to="#services" smooth={true}>Services</HashLink></li>
          <li className="app__paraHeading"><HashLink to="#gallery" smooth={true}>Gallery</HashLink></li>
          <li className="app__paraHeading"><HashLink to ="#contact" smooth={true}>Contact</HashLink></li>
        </ul>
        <hr />
        <ul className="app__navbar-links_bottom">
          <li className="app__para animate-charcter"><Link to ='impurities'>Impurities</Link></li>
          <li className="app__para animate-charcter"><Link to ='hplccolumn'>HPLC Column</Link></li>
          <li className="app__para animate-charcter"><Link to ='gccolumn'>GC Column</Link></li>
          <li className="app__para animate-charcter"><Link to ='lab_glassware'>Lab Glassware</Link></li>
          <li className="app__para animate-charcter"><Link to='dissolution_accessories'>Dissolution Accessories</Link></li>
          <li className="app__para animate-charcter"><Link to='instruments'>Instruments</Link></li>
          <li className="app__para animate-charcter"><Link to='hplc_accessories'>HPLC Accessories</Link></li>
          <li className="app__para animate-charcter"><Link to='gc_accessories'>GC Accessories</Link></li>
          <li className="app__para animate-charcter"><Link to='syringe_filters'>Syringe Filters</Link></li>
          <li className="app__para animate-charcter"><Link to='vials'>Vials</Link></li>
        </ul>
      </div>

      {/* Mobile Menu  */}
      <div className="app__navbar-smallscreen">
        <ImMenu3 color='var(--color-base)' size={27} onClick={() => setToggleMenu(true)} />
        {
          toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <ImMenu4 color='var(--color-base)' size={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
              <ul className="app__navbar-smallscreen_links">
                <li><HashLink to="#home" onClick={() => setToggleMenu(false)}>Home</HashLink></li>
                <li className="app__paraHeading"><HashLink to ="#products" smooth={true} onClick={()=>setToggleMenu(false)}>Products</HashLink></li>
                <li className="app__paraHeading"><HashLink to ="#about" smooth={true} onClick={()=>setToggleMenu(false)}>About</HashLink></li>
                <li className="app__paraHeading"><HashLink to ="#services" smooth={true} onClick={()=>setToggleMenu(false)}>Services</HashLink></li>
                <li className="app__paraHeading"><HashLink to ="#gallery" smooth={true} onClick={()=>setToggleMenu(false)}>Gallery</HashLink></li>
                <li className="app__paraHeading"><HashLink to ="#contact" smooth={true} onClick={()=>setToggleMenu(false)}>Contact</HashLink></li>
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Navbar