import React from 'react';
import './services.css'
import images from '../../constants'

const Services = () => {
    return (
        <div className="app__services section__padding animate-charcter" id='services'>
            {/* Heading  */}
            <div className="app__heading" style={{ textAlign: 'center' }}>Our Services</div>
            <div className="app__para">We are committed to providing our customers with the best possible service and support. Expert service and Technical support is available for all products through qualified persons. With customer satisfaction our highest priority, we offer the following service and support programs</div>

            {/* Servives  */}

            <div className="app__services_content">
                {/* 1st Row  */}
                <div className="app__services_content-row">
                    <div className="app__services_content-service">
                        <div className="app__services_content-row_text" style={{textAlign:'right'}}>
                            <div className="app__paraHeading">Technical Support</div>
                            <div className="app__para">With the best Technical Support Team in the business Synchem Chromatography Technologies stands ready to assist you with your precision fluid measuring needs however we can</div>
                        </div>
                        <div className="app__services_content-row_img">
                            <img src={images.technical} alt="" />
                        </div>
                    </div>
                    <div className="app__services_content-service">
                        <div className="app__services_content-row_img">
                            <img src={images.replacement} alt="" />
                        </div>
                        <div className="app__services_content-row_text">
                            <div className="app__paraHeading">Replacement Services</div>
                            <div className="app__para">Any product, which fails due to defects, will be repaired or replaced at our discretion without cost, provided the device is returned with an explanation. </div>
                        </div>
                    </div>
                </div>

                <div className="app__services_content-row">
                    <div className="app__services_content-service">
                        <div className="app__services_content-row_text" style={{textAlign:'right'}}>
                            <div className="app__paraHeading">Round The Clock Service</div>
                            <div className="app__para">Commitment is our culture and Promptness is our Passion. We will be at the reach of your hand at any hour a day ,all the 24 hours. We are just a phone call or a simple email away to serve you.</div>
                        </div>
                        <div className="app__services_content-row_img">
                            <img src={images.always} alt="" />
                        </div>
                    </div>
                    <div className="app__services_content-service">
                        <div className="app__services_content-row_img">
                            <img src={images.fast} alt="" />
                        </div>
                        <div className="app__services_content-row_text">
                            <div className="app__paraHeading">Fast Service</div>
                            <div className="app__para">We are popular in the arena for faster deliveries and quicker responses. We always look forward to satisfy our customers with the best services we can offer with qualified personnel. Customer satisfaction is our ultimate goal.</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Services