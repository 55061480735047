import React from 'react'
import { Navbar } from '../../components'
import { About, Clipart, Contact, Gallery, Homescreen, Services } from '../../container'
import Slider from '../../container/Slider/Slider'

const Home = () => {
  return (
    <div>
        {/* <Homescreen/> */}
        <Slider/>
        <Clipart/>
        <About/>
        <Services/>
        <Gallery/>
        <Contact/>
    </div>
  )
}

export default Home