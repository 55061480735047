import images from "./images";

// const glasswareOne = [
//     {
//         imgUrl: images.glasswareOne,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareTwo,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareThree,
//         title: 'ProductTwo'
//     },
//     {
//         imgUrl: images.glasswareFour,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareFive,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareSix,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareSix,
//         title: 'ProductOne'
//     },
// ]

// const glasswareTwo = [
//     {
//         imgUrl: images.glasswareOne,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareTwo,
//         title: 'ProductOne'
//     },
//     {
//         imgUrl: images.glasswareThree,
//         title: 'ProductTwo'
//     },
//     {
//         imgUrl: images.glasswareFour,
//         title: 'ProductOne'
//     },

// ]

const clipart = [
    {
        imgUrl: images.hplc_column,
        title: 'HPLC Column',
        URL : '/impurities'
    }, 
    {
        imgUrl: images.hplc_column,
        title: 'HPLC Column',
        URL : 'impurities'
    },
    {
        imgUrl: images.gc_column,
        title: 'GC Column',
        URL : 'impurities'
    },
    {
        imgUrl: images.labglassware,
        title: 'Lab Glassware',
        URL : 'impurities'
    },
    {
        imgUrl: images.da,
        title: 'Dissolution Accessories',
        URL : 'impurities'
    },
    {
        imgUrl: images.instruments,
        title: 'Instruments',
        URL : 'impurities'
    },
    {
        imgUrl: images.hplc_a,
        title: 'HPLC Accessories',
        URL : 'impurities'
    },
    {
        imgUrl: images.gc_a,
        title: 'GC Accessories',
        URL : 'impurities'
    },
    {
        imgUrl: images.syringe_filters,
        title: 'Syringe Filters',
        URL : 'impurities'
    },
    {
        imgUrl: images.vials,
        title: 'Vials $ Septas',
        URL : 'impurities'
    }
]


const clipartTwo = [
    {
        imgUrl: images.clip1,
        title: 'Impurities',
        URL : 'impurities'
    }, {
        imgUrl: images.clip2,
        title: 'HPLC Column',
        URL : 'hplccolumn'
    },
    {
        imgUrl: images.clip3,
        title: 'GC Column',
        URL : 'gccolumn'
    },
    {
        imgUrl: images.clip4,
        title: 'Lab Glassware',
        URL : 'lab_glassware'
    },
    {
        imgUrl: images.clip5,
        title: 'Dissolution Accessories',
        URL : 'dissolution_accessories'
    },
    {
        imgUrl: images.clip6,
        title: 'Instruments',
        URL : 'instruments'
    },
    {
        imgUrl: images.clip7,
        title: 'HPLC Accessories',
        URL : 'hplc_accessories'
    },
    {
        imgUrl: images.clip8,
        title: 'GC Accessories',
        URL : 'gc_accessories'
    },
    {
        imgUrl: images.clip9,
        title: 'Syringe Filters',
        URL : 'syringe_filters'
    },
    {
        imgUrl: images.clip10,
        title: 'Vials $ Septas',
        URL : 'vials'
    }
]

const impurities = [
    {
        imgUrl: images.impurities1,
        title: 'N-Nitroso pyrrolidine'
    },
    {
        imgUrl: images.impurities2,
        title: 'N-Nitroso morpholine '
    },
    {
        imgUrl: images.impurities3,
        title: 'N-Nitrosodipropylamine '
    },
    {
        imgUrl: images.impurities4,
        title: 'N-Nitrosopiperidine'
    },
    {
        imgUrl: images.impurities5,
        title: 'N-Nitrosodimethylamine '
    },
    {
        imgUrl: images.impurities6,
        title: 'N-Ethyl-N-nitroso2-P'
    },
    {
        imgUrl: images.impurities7,
        title: 'N-Nitrosodiethylamine'
    },
    {
        imgUrl: images.impurities8,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities9,
        title: 'N-Methyl-N-nitroso2-propanamine'
    },
    {
        imgUrl: images.impurities10,
        title: 'N-Nitroso-Nmethyl-4-aminobutyric Acid '
    },
    {
        imgUrl: images.impurities11,
        title: 'N-Nitroso-di-nbutylamine'
    },
    {
        imgUrl: images.impurities12,
        title: 'N-Ethyl-N-nitroso2-propanamine '
    },
    {
        imgUrl: images.impurities13,
        title: 'Nitroso bis(2-hydroxyethyl)amine'
    },
    {
        imgUrl: images.impurities14,
        title: 'N-Nitroso-Nmethylaniline'
    },
    {
        imgUrl: images.impurities15,
        title: 'NNitrosoethylmethylamine'
    },
    {
        imgUrl: images.impurities16,
        title: 'N-tert-Butyl-N-ethylnitrosamine'
    },
    {
        imgUrl: images.impurities17,
        title: 'Bis(2-chloroethyl)nitrosamine'
    },
    {
        imgUrl: images.impurities18,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities19,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities20,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities21,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities22,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities23,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities24,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities25,
        title: 'N-Nitrosodiisopropylamine'
    },
    {
        imgUrl: images.impurities26,
        title: 'N-Nitrosodiisopropylamine'
    },
]

const hplccolumn = [
    {
        imgUrl : images.hplc1,
        title : 'HPLC Column'
    }
]

const gccolumn = [
    {
        imgUrl : images.gccolumn1,
        title : 'Packed GC Column'
    },
    {
        imgUrl : images.gccolumn3,
        title : 'Capillary GC Column'
    },
    {
        imgUrl : images.gccolumn2,
        title : 'GC Column'
    }
]

const labglassware = [
    {
        imgUrl : images.lg1,
        title : 'Breaker'
    },
    {
        imgUrl : images.lg2,
        title : 'Bottles'
    },
    {
        imgUrl : images.lg3,
        title : 'Burette'
    },
    {
        imgUrl : images.lg4,
        title : 'Flask'
    },
    {
        imgUrl : images.lg5,
        title : 'Measuring Cylinder'
    },
    {
        imgUrl : images.lg6,
        title : 'Pipette'
    },
    {
        imgUrl : images.lg7,
        title : 'Volumetric Flasks'
    },
    {
        imgUrl : images.lg8,
        title : 'Test-Tube'
    },
    {
        imgUrl : images.lg9,
        title : 'Wash Bottle'
    },
    {
        imgUrl : images.lg10,
        title : 'Condensers'
    },
    {
        imgUrl : images.lg11,
        title : 'Crucibles'
    },
    {
        imgUrl : images.lg12,
        title : 'Desiccator'
    },
    {
        imgUrl : images.lg13,
        title : 'TLC Jars'
    },
    {
        imgUrl : images.lg14,
        title : 'Mortar & Pastle'
    },

    {
        imgUrl : images.lg15,
        title : 'Buchner Funnel'
    },
    {
        imgUrl : images.lg16,
        title : 'Magnetic Stirrer'
    },
    {
        imgUrl : images.lg17,
        title : 'Sillicon Tube'
    },
    {
        imgUrl : images.lg18,
        title : 'Rubber Tube'
    },
    {
        imgUrl : images.lg19,
        title : 'TestTube Stand PP'
    },
    {
        imgUrl : images.lg20,
        title : 'Rubber Cones'
    },
    {
        imgUrl : images.lg21,
        title : 'Rubber Corks'
    },
    {
        imgUrl : images.lg22,
        title : 'Boss Head'
    },
    {
        imgUrl : images.lg23,
        title : 'PP Spatula'
    },

    {
        imgUrl : images.lg24,
        title : 'PP Droppers'
    },
    {
        imgUrl : images.lg25,
        title : 'Powder Funnels'
    },
    {
        imgUrl : images.lg26,
        title : 'PP VolumetricFlask'
    },
    {
        imgUrl : images.lg27,
        title : 'RBF Base'
    },
    {
        imgUrl : images.lg28,
        title : 'PP Burettes'
    },
    {
        imgUrl : images.lg29,
        title : 'Nylon Brushes'
    },
    {
        imgUrl : images.lg30,
        title : 'PP Amber Bottles'
    },
    {
        imgUrl : images.lg31,
        title : 'PP Clear Bottle'
    },
    {
        imgUrl : images.lg32,
        title : 'PP Amber Bottles'
    },

    {
        imgUrl : images.lg33,
        title : 'PP Clear Bottle'
    },
    {
        imgUrl : images.lg34,
        title : 'SS Forceps'
    },
    {
        imgUrl : images.lg35,
        title : 'Vaccum Tube'
    },
    {
        imgUrl : images.lg36,
        title : 'SS Tripod Stand'
    },
    {
        imgUrl : images.lg37,
        title : 'SS Tongs'
    },
    {
        imgUrl : images.lg38,
        title : 'SS TestTube Stand'
    },
    {
        imgUrl : images.lg39,
        title : 'Safety Rings'
    },
    {
        imgUrl : images.lg40,
        title : 'SS Spoon Spatula'
    },
    {
        imgUrl : images.lg41,
        title : 'Rubber Bulbs'
    },
    {
        imgUrl : images.lg42,
        title : '3 Finger Clamp'
    },
    {
        imgUrl : images.lg43,
        title : 'Sonicator'
    },
    {
        imgUrl : images.lg44,
        title : 'Culture Tubes'
    },
    {
        imgUrl : images.lg45,
        title : 'Glass Rods'
    },
    {
        imgUrl : images.lg46,
        title : 'Glass Stoppers'
    },
    {
        imgUrl : images.lg47,
        title : 'PP Stoppers'
    },
    {
        imgUrl : images.lg48,
        title : 'Glass Stoppers'
    },
    {
        imgUrl : images.lg49,
        title : 'Solid Stoppers'
    },
    {
        imgUrl : images.lg50,
        title : 'Hexagonal Stoppers'
    },
    {
        imgUrl : images.lg51,
        title : 'Glass Stopper'
    },
    {
        imgUrl : images.lg52,
        title : 'Screw Caps'
    },
    {
        imgUrl : images.lg53,
        title : 'PP Beakers'
    },


    
]

const dissolution_accessories = [
    {
        imgUrl : images.da1,
        title : 'Basket Mesh'
    },
    {
        imgUrl : images.da2,
        title : 'Bent Cannula'
    },
    {
        imgUrl : images.da3,
        title : 'Bent Peek Cannula'
    },
    {
        imgUrl : images.da4,
        title : 'Basket Shaft'
    },
    {
        imgUrl : images.da5,
        title : 'Cannula Filter'
    },
    {
        imgUrl : images.da6,
        title : 'Spiral Capsule Sinker'
    },
    {
        imgUrl : images.da7,
        title : 'Spring Style Capsule Sinker'
    },
    {
        imgUrl : images.da8,
        title : 'Prong Capsule Sinker'
    },
    {
        imgUrl : images.da9,
        title : 'O Ring Capsule Sinker'
    },
    {
        imgUrl : images.da11,
        title : 'Round Capsule Sinker'
    },
    {
        imgUrl : images.da10,
        title : 'Paddle'
    },
    {
        imgUrl : images.da12,
        title : 'Vessel'
    },
    {
        imgUrl : images.da13,
        title : 'Vessel'
    },
]



const instruments = [
    {
        imgUrl : images.ins1,
        title : 'pH Meter'
    },
    {
        imgUrl : images.ins2,
        title : 'Water Bath'
    },
    {
        imgUrl : images.ins3,
        title : 'Centrifuze'
    },
    {
        imgUrl : images.ins4,
        title : 'Friability Apparatus'
    },
    {
        imgUrl : images.ins5,
        title : 'Hot Plate & Magnetic Stirrer'
    },
    {
        imgUrl : images.ins6,
        title : 'Heating Mantle'
    },
    {
        imgUrl : images.ins7,
        title : 'HPLC Column Oven'
    },
    {
        imgUrl : images.ins8,
        title : 'Sonicator'
    }
]

const hplcaccessories = [
    {
        imgUrl : images.hplc_a1,
        title : 'HPLC Column Rack'
    },
    {
        imgUrl : images.hplc_a2,
        title : 'Peek Nuts'
    },
    {
        imgUrl : images.hplc_a3,
        title : 'Peek Tubing'
    }
]


const gcaccessories = [
    {
        imgUrl : images.GC_A1,
        title : 'Capillary Tube Cutter'
    },
    {
        imgUrl : images.GC_A2,
        title : 'Graphite Ferrules'
    },
]

const syringefilters = [
    {
        imgUrl : images.SF1,
        title : 'Syringe Filter'
    },
    {
        imgUrl : images.SF2,
        title : 'Syringe Filter'
    },
]

const vials = [
    {
        imgUrl : images.vials1,
        title : 'HPLC Vials & Caps'
    },
    {
        imgUrl : images.vials2,
        title : 'HPLC Vials & Caps'
    },
]

export default {clipart, impurities, hplccolumn, gccolumn, labglassware, dissolution_accessories, clipartTwo, instruments, hplcaccessories, gcaccessories, syringefilters, vials }