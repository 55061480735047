import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'
import imp from '../../assets/impurities/imp1.jpg'


const Impurities = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">Impurities</div>
    <div clasName="app__para">Chromatographic impurity profiles are most often developed using reversed-phase high-performance liquid chromatography (RP-HPLC). The chromatographic impurity profile should allow detecting and separating all (un)identified impurities in each new active compound</div>
    <img src={imp} alt="" style={{width:'100%', height:'600px'}} />
    {/* <div className="app__products_product">
      {
        data.impurities.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div> */}
  </div>
  )
}

export default Impurities