import React from 'react'
import './clipart.css'
import data from '../../constants/data'
import { Link } from 'react-router-dom'

const SvgCard = ({image : {imgUrl, title, URL}}) => (
  <div className="app__clipart_svg">
    <div> <Link to={URL}><img src={imgUrl} alt="" /></Link></div>
    <p className="">{title}</p>
  </div>
)

const Clipart = () => {
  return (
    <div className="app__clipart">
        <div className="app__heading" style={{textAlign : 'center'}}>Our Products Range</div>
        <div className="app__clipart_section">
          {
            data.clipartTwo.map( (image) => < SvgCard image={image} key={image.title}/>)
          }
        </div>
    </div>
  )
}

export default Clipart