import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const Vials = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">Vials & Septas</div>
    <div className="app__para">A vial (also known as a phial or flacon) is a small glass or plastic vessel or bottle, often used to store medication as liquids, powders or capsules. They can also be used as scientific sample vessels; for instance, in autosampler devices in analytical chromatography.</div>
    <div className="app__products_product">
      {
        data.vials.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default Vials