import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const LabGlassware = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">Lab Glassware</div>
    <div className="app__para">Laboratory glassware can be used for high precision volumetric measurements. With high precision measurements, such as those made in a testing laboratory, the metrological grade of the glassware becomes important.</div>
    <div className="app__products_product">
      {
        data.labglassware.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default LabGlassware