import React from 'react'
import { ProductCard } from '../../components'
import data from '../../constants/data'

const HplcColumn = () => {
  return (
    <div className="app__products section__padding">
    <div className="app__heading">HPLC Columns</div>
    <div className="app__para">Ion exchange HPLC columns can be used to separate many types of analytes and are commonly used for separations of carbohydrates, amino acids, and proteins. Ion exchange and ligand exchange chromatography may be combined in a column.</div>
    <div className="app__products_product">
      {
        data.hplccolumn.map((product) => <ProductCard product={product} key={product.title} />)
      }
    </div>
  </div>
  )
}

export default HplcColumn